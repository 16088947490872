// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  serverUrl: 'https://ogvvv9dm0h.execute-api.us-east-2.amazonaws.com/dev',
  bucket: 'redthreadaudit-dev-upload',
  userPool: {
    UserPoolId: 'us-east-2_nz3DdrAga',
    ClientId: '5etut3rqut2psc9aduub9mri00',
    region: 'us-east-2'
  },
  identityPoolId: 'us-east-2:99b67682-5fc9-4e4b-92a1-9e749c3383c5',
  buckets: {
    'us-east-2': 'redthreadaudit-dev-upload'
  },
  modules: {
    key: 'analysisType',
    values: [
      {
          'name': 'SMR',
          'desc': 'Small Molecule Report'
      },
      {
          'name': 'LMR',
          'desc': 'Large Molecule Report'
      },
      {
        'name': 'qPCR',
        'desc': 'QPCR Molecule Report'
      }
       
    ]
},
  defaultRegion: 'us-east-2',
  cognito: {
    identityPoolId: 'us-east-2:99b67682-5fc9-4e4b-92a1-9e749c3383c5', // REQUIRED - Amazon Cognito Identity Pool ID
    region: 'us-east-2', // REQUIRED - Amazon Cognito Region
    userPoolId: 'us-east-2_nz3DdrAga', // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: '5etut3rqut2psc9aduub9mri00', // OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
      bucket: 'redthreadaudit-dev-upload', // REQUIRED -  Amazon S3 bucket
      region: 'us-east-2', // OPTIONAL -  Amazon service region
    }
  }
};
const awsconfig = {
  'aws_project_region': 'us-east-2',
  'aws_cognito_region': 'us-east-2',
  'aws_user_pools_id': 'us-east-2_nz3DdrAga',
  'aws_user_pools_web_client_id': '5etut3rqut2psc9aduub9mri00',
  'aws_cognito_mfa_configuration': 'OPTIONAL',

};
export default awsconfig;

