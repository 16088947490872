// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .main-content {
  padding: 70px 0 0 220px;
  background-color: white;
  transition: padding 0.2s ease-in-out;
}
@media screen and (max-width: 2048px) {
  :host .main-content {
    padding-left: 0;
  }
}
:host navbar {
  transition: left 0.2s ease-in-out, right 0.2s ease-in-out;
}
@media screen and (max-width: 2048px) {
  :host navbar {
    width: 100%;
  }
}
:host .open-sidebar .main-content {
  animation-duration: 0.2s;
  padding: 70px 0 0 220px;
}
:host .open-sidebar navbar {
  left: 220px;
}
:host .open-sidebar sidebar {
  left: 0;
}
@media screen and (max-width: 2048px) {
  :host .open-sidebar sidebar ::ng-deep .sidebar-overlay {
    opacity: 1;
    transition: 0.2s opacity 0s ease-in-out, 0s visibility 0s ease-in-out;
    visibility: visible;
  }
}
:host .rtl navbar {
  left: 0;
}
@media screen and (max-width: 2048px) {
  :host .rtl navbar {
    left: auto;
  }
}
:host .rtl.open-sidebar navbar {
  right: 220px;
}
:host .rtl.open-sidebar sidebar {
  left: auto;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/default/default.component.scss"],"names":[],"mappings":"AAGE;EACE,uBAAA;EACA,uBAAA;EACA,oCAAA;AAFJ;AAGI;EAJF;IAKI,eAAA;EAAJ;AACF;AAEE;EACE,yDACE;AADN;AAGI;EAJF;IAKI,WAAA;EAAJ;AACF;AAGI;EACI,wBAAA;EACA,uBAAA;AADR;AAGI;EACE,WAAA;AADN;AAGI;EACE,OAAA;AADN;AAIQ;EADF;IAEI,UAAA;IACA,qEACE;IAEF,mBAAA;EAHR;AACF;AAQI;EACE,OAAA;AANN;AAOM;EAFF;IAGI,UAAA;EAJN;AACF;AAeM;EACE,YAAA;AAbR;AAeM;EACE,UAAA;EACA,QAAA;AAbR","sourcesContent":["@import '../../../assets/sass/variable';\n\n:host {\n  .main-content {\n    padding: 70px 0 0 $sidebar-width;\n    background-color: white;\n    transition: padding .2s $animation;\n    @media screen and (max-width: 2048px) {\n      padding-left: 0;\n    }\n  }\n  navbar {\n    transition:\n      left .2s $animation,\n      right .2s $animation;\n    @media screen and (max-width: 2048px) {\n      width: 100%;\n    }\n  }\n  .open-sidebar {\n    .main-content {\n        animation-duration: .2s;\n        padding: 70px 0 0 $sidebar-width;\n    }\n    navbar {\n      left: 220px;\n    }\n    sidebar {\n      left: 0;\n\n      ::ng-deep .sidebar-overlay {\n        @media screen and (max-width: 2048px) {\n          opacity: 1;\n          transition:\n            .2s opacity 0s $animation,\n            0s visibility 0s $animation;\n          visibility: visible;\n        }\n      }\n    }\n  }\n  .rtl {\n    navbar {\n      left: 0;\n      @media screen and (max-width: 2048px) {\n        left: auto;\n      }\n    }\n    // .main-content {\n    //   padding-left: 0;\n\n    //   padding-right: $sidebar-width;\n    //   @media #{$max2048} {\n    //     padding-right: 0;\n    //   }\n    // }\n    &.open-sidebar {\n      navbar {\n        right: 220px;\n      }\n      sidebar {\n        left: auto;\n        right: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
