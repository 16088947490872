// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputbox {
  float: right;
  position: absolute;
}

.expan-panel {
  position: relative;
  display: block;
}

label {
  display: inline-block;
  text-align: right;
  margin-right: 10px;
}

.table-display {
  margin-bottom: 10px;
}

.fa-info-circle {
  position: absolute;
}

::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white;
}
::ng-deep .mat-mdc-form-field-error {
  display: flex;
}
::ng-deep .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-bottom: 0px;
}

.required-field::after {
  content: "*";
  color: rgb(0, 0, 0); /* Customize the asterisk style as needed */
}

.disabled-input {
  background-color: #f5f5f5; /* Light gray background color */
  color: #888; /* Gray text color */
}`, "",{"version":3,"sources":["webpack://./src/app/pages/new-analysis/small-large-report/small-large-report.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,kBAAA;AAAJ;;AAGE;EACE,kBAAA;EACA,cAAA;AAAJ;;AAGE;EACE,qBAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;;AAGE;EACE,mBAAA;AAAJ;;AAGE;EACE,kBAAA;AAAJ;;AAKI;EACE,uBAAA;AAFN;AAKE;EACE,aAAA;AAHJ;AAKA;EACG,mBAAA;AAHH;;AAQE;EACE,YAAA;EACA,mBAAA,EAAA,2CAAA;AALJ;;AAQE;EACE,yBAAA,EAAA,gCAAA;EACA,WAAA,EAAA,oBAAA;AALJ","sourcesContent":["\n  .inputbox{\n    float: right;\n    position: absolute;\n  }\n\n  .expan-panel{\n    position: relative;\n    display: block;\n  }\n\n  label{\n    display: inline-block;\n    text-align: right;\n    margin-right: 10px;\n  }\n\n  .table-display{\n    margin-bottom: 10px;\n  }\n\n  .fa-info-circle{\n    position: absolute;\n\n  }\n\n  ::ng-deep {\n    .mdc-text-field--filled:not(.mdc-text-field--disabled) {\n      background-color:white;\n  }\n\n  .mat-mdc-form-field-error {\n    display: flex;\n}\n.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {\n   padding-bottom:0px; \n}\n\n  }\n\n  .required-field::after {\n    content: \"*\";\n    color: rgb(0, 0, 0); /* Customize the asterisk style as needed */\n  }\n\n  .disabled-input {\n    background-color: #f5f5f5; /* Light gray background color */\n    color: #888; /* Gray text color */\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
