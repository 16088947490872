import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {AnalysisKey, RDSAnalysisData} from '../../../model/analysis-details.model';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { AuthNewService } from '../../../service/auth-new.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('reportData') reportData!: ElementRef;
  
  dataSource: MatTableDataSource<RDSAnalysisData>;
  tableColumns: string[] = [ 'fileName','projectCode', 'analysisDate', 'status' , 'userId'];
  @Input() searchResults: RDSAnalysisData[] = [];
  private currentLoginUser: Subscription;
  userAttributes
  signedInUser
  illegalUser:boolean;
  showTable:boolean=false

  constructor(
    private authService: AuthNewService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.authService.getCurrentUser();
    this.currentLoginUser = this.authService.getCurrentLoginUser.subscribe((user: any) => {
      this.signedInUser = user
      this.userAttributes = user.attributes["custom:access"].toLowerCase();
      if (!this.signedInUser) {
        this.router.navigate(['/extra-layout/signin']);
        return;
      }
      else if (this.userAttributes === 'pi') {
        this.illegalUser = true;
      }
      else if (this.userAttributes === 'ga'|| this.userAttributes === 'su'|| this.userAttributes === 'qa'){
        this.showTable = true;
        this.illegalUser = false;
        this.dataSource = new MatTableDataSource(this.searchResults);
        setTimeout(() => this.dataSource.paginator = this.paginator);
        setTimeout(() => this.dataSource.sort = this.sort);

      }
    });

  }

  getUrl(analysisType: string) {
     if (analysisType === 'SMR' || analysisType === 'LMR' || analysisType === 'ADA') {
      return '/default-layout/molecule-analysis';
    } else {
      return '/default-layout/notfound';
    }
  }

  public openPDF(): void {
    const DATA: any = document.getElementById('reportData');
    html2canvas(DATA).then((canvas) => {
      const fileWidth = 208 ;
      const fileHeight = (canvas.height * fileWidth) / canvas.width ;
      const FILEURI = canvas.toDataURL('image/png');
      const PDF = new jsPDF('p', 'mm', 'a4');

      const position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('report-analysis.pdf');
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
