// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  background-size: cover;
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  width: 100%;
}
:host card {
  margin: 0 auto;
  max-width: 400px;
}
:host card ::ng-deep .card-wrap {
  border: 0;
}
:host card form button {
  width: 100%;
}
:host card form .additional-info {
  background: rgba(0, 0, 0, 0.1);
  margin: 1.5714286rem -1.5714286rem -1.5714286rem;
  padding: 0.7857143rem 1.5714286rem;
}
:host card form .additional-info .divider {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  display: inline-block;
  height: 18px;
  margin: 0 10px;
  vertical-align: bottom;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/extra-pages/sign-in-1/sign-in-1.component.scss"],"names":[],"mappings":"AAEA;EAEE,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,sBAAA;EACA,WAAA;AAFF;AAIE;EACE,cAAA;EACA,gBAAA;AAFJ;AAII;EACE,SAAA;AAFN;AAKM;EACE,WAAA;AAHR;AAKM;EACE,8BAAA;EACA,gDAAA;EACA,kCAAA;AAHR;AAKQ;EACE,yCAAA;EACA,qBAAA;EACA,YAAA;EACA,cAAA;EACA,sBAAA;AAHV","sourcesContent":["@import '../../../../assets/sass/variable';\n\n:host {\n  // background: url(../../../../assets/content/login-bg.jpg) 50% 50% no-repeat;\n  background-size: cover;\n  display: table-cell;\n  height: 100%;\n  vertical-align: middle;\n  width: 100%;\n\n  card {\n    margin: 0 auto;\n    max-width: 400px;\n\n    ::ng-deep .card-wrap {\n      border: 0;\n    }\n    form {\n      button {\n        width: 100%;\n      }\n      .additional-info {\n        background: rgba(#000,.1);\n        margin: $module (-$module) (-$module);\n        padding: $module/2 $module;\n\n        .divider {\n          border-left: 1px solid rgba(#000,.2);\n          display: inline-block;\n          height: 18px;\n          margin: 0 10px;\n          vertical-align: bottom;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
