import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dynamic-form-builder',
  template: `
    <form (ngSubmit)="onSubmit(this.form.value)" #f [formGroup]="form" class="form-horizontal">
      <div class=form-row>
        <div class="col">
          <div *ngFor="let field of fields">
            <app-field-builder [field]="field" [form]="form"></app-field-builder>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button type="submit" [disabled]="!form.valid" class="btn btn-primary">Submit</button>
      </div>
    </form>
  `,
})
export class DynamicFormBuilderComponent implements OnInit {
  @Output() formData = new EventEmitter();
  @Input() fields: any[] = [];
  form: FormGroup;
  constructor() {

  }

  ngOnInit() {
    const fieldsCtrls = {};
  
    for (const f of this.fields) {
      if (f.type !== 'checkbox') {
        fieldsCtrls[f.name] = new FormControl(f.value || '', Validators.required)
      } else {
        const opts = {};
        for (const opt of f.options) {
          opts[opt.key] = new FormControl(opt.value);
        }
        fieldsCtrls[f.name] = new FormGroup(opts)
      }
    }
    this.form = new FormGroup(fieldsCtrls);
  }

  onSubmit(data) {
    this.formData.emit(data);
  }

}
