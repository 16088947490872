// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center_div {
  margin-top: 10px;
  margin-left: 50px;
  width: 70%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/auth/signup/verification/verification.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,UAAA;AACF","sourcesContent":[".center_div{\n  margin-top: 10px;\n  margin-left: 50px;\n  width:70% /* value of your choice which suits your alignment */\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
