// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  align-items: center;
  background: #ffffff;
  display: flex;
  font-size: 0.8rem;
  height: 3.1428572rem;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 543px) {
  :host {
    font-size: 0.7rem;
  }
}
:host .copyright,
:host .version {
  padding: 10px 22px;
}
@media screen and (max-width: 543px) {
  :host .copyright {
    padding-right: 0;
  }
  .rtl :host .copyright {
    padding-left: 0;
    padding-right: 22px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/rt-components/footer/footer.component.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,iBAAA;EACA,oBAAA;EACA,8BAAA;EACA,WAAA;AADF;AAEE;EARF;IASI,iBAAA;EACF;AACF;AACE;;EAEE,kBAAA;AACJ;AAEI;EADF;IAEI,gBAAA;EACJ;EACI;IACE,eAAA;IACA,mBAAA;EACN;AACF","sourcesContent":["@import '../../../assets/sass/variable';\n\n:host {\n  align-items: center;\n  background: #ffffff;\n  display: flex;\n  font-size: .8rem;\n  height: $module * 2;\n  justify-content: space-between;\n  width: 100%;\n  @media screen and (max-width: 543px) {\n    font-size: .7rem;\n  }\n\n  .copyright,\n  .version {\n    padding: 10px 22px;\n  }\n  .copyright {\n    @media screen and (max-width: 543px){\n      padding-right: 0;\n\n      .rtl & {\n        padding-left: 0;\n        padding-right: 22px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
