import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../layouts/shared-service';
import { AuthService, DBService, DownLoadService } from '../../service';
import { Router } from '@angular/router';
import { DropDownService } from '../../service/drop-down.service';
import { User } from '../../model/types';
import { AnalysisKey, AuthLog, Logs, PreviousAnalysis, RDSAnalysisData } from '../../model/analysis-details.model';
import Utils from '../../utility/Utils';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { FormControl } from '@angular/forms';
import { AuthNewService } from '../../service/auth-new.service';
import { Subscription } from 'rxjs';
import { StorageService } from '../../service/storage.service';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class UserComponent implements OnInit {
  date = new FormControl(moment());
  pageTitle = 'User Account';
  signedInUser: any;
  loading = false;
  username: string;
  email: string;
  reportKey = '';
  fileLink;
  logdata: Array<AuthLog> = [];
  users: any[] = [];
  previousAnalysisKeys: RDSAnalysisData[] = [];
  pAfileLink;
  loginSelected = '';
  ctrlValueMonth;
  ctrlValueYear;
  monthSelected = '';
  maxYear;
  temp_loginData: any;
  temp_analysisData: any;
  clicked = false;
  months = ['January', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  pageLoaded = false;
  title = 'Loading';
  @ViewChild('datePicker') public dp;
  role 
  startDate;
  endDate;
  illegalUser: boolean
  showTable: boolean = false
  private rdsAnalysisDataSubscription: Subscription;
  private currentLoginUser: Subscription;
  userAttributes
  maxDate = new Date();
  auditFlag:boolean=false

  constructor(private _sharedService: SharedService,
    private authService: AuthNewService,
    private router: Router,
    private dbService: DBService,
    private storageService:StorageService) {
    // this.temp_loginData = JSON.parse(sessionStorage.getItem('login-data'));
    // this.temp_analysisData = JSON.parse(sessionStorage.getItem('analysis-data'));
    this._sharedService.emitChange(this.pageTitle);

    this.role = this.storageService.get('access');
    // this.dbService.rdsAnalysisData.subscribe(
    //   (data: RDSAnalysisData[]) => {

    //     this.previousAnalysisKeys = data;
    //     console.log('RDS Analysis Data on Audit page', this.previousAnalysisKeys);
    //   }
    // );


  }

  ngOnInit(): void {
    const currentYear = moment().toDate();
    this.maxYear = currentYear;
    this.authService.getCurrentUser();
    this.currentLoginUser = this.authService.getCurrentLoginUser.subscribe((user: any) => {
      this.signedInUser = user;
      this.userAttributes = user.attributes["custom:access"].toLowerCase();
      if (!this.signedInUser) {
        this.router.navigate(['/extra-layout/signin']);
        return;
      }
      else if (this.userAttributes === 'pi') {
        this.illegalUser = true;
      }
      else if (this.userAttributes === 'ga' || this.userAttributes=== 'su' || this.userAttributes === 'qa') {
        this.illegalUser = false;
        this.showTable = true;
        this.auditFlag=true
        this.dbService.getAnalysis(this.role, this.startDate, this.endDate,this.auditFlag);

        this.rdsAnalysisDataSubscription = this.dbService.rdsAnalysisData.subscribe(
          (data: RDSAnalysisData[]) => {
          
            this.previousAnalysisKeys = data;
          }
        );
      }
    });
  }
  passSelectedDates() {
    this.previousAnalysisKeys = []

    const data = this.dbService.getAnalysis(this.role, this.startDate, this.endDate,this.auditFlag)
  
  }

  selectedDates(dateRangeStart, dateRangeEnd) {
    this.startDate = dateRangeStart.value;
    this.endDate = dateRangeEnd.value;
    this.passSelectedDates();
    
  }

  ngOnDestroy() {
    // Unsubscribe from the observable to avoid memory leaks
    if (this.rdsAnalysisDataSubscription) {
      this.rdsAnalysisDataSubscription.unsubscribe();
    }
    if (this.currentLoginUser) {
      this.currentLoginUser.unsubscribe();
    }
  }

}


