// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center {
  width: 25%;
  margin: 10px auto;
}

.main-div {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nolist {
  list-style: none;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/analysis-details/analysis-details.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AACA;EACE,gBAAA;AAEF","sourcesContent":[".center{\n  width: 25%;\n  margin: 10px auto;\n}\n\n.main-div{\n  height: 30vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.nolist {\n  list-style: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
