// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-form-field {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/amplify-auth/user-sign-in/user-sign-in.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ","sourcesContent":[".mat-mdc-form-field{\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
