import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DBService, UploadService } from '../../service';
import { ContainerEvents } from './types';
import {
    AnalysisDetails,
    Analyte,
    FileObject,
    FileObjectStatus,
    RDSAnalysisData,
    Uploads
} from '../../model/analysis-details.model';
import { AuthService } from '../../service';
import { User } from '../../model/types';
import { SharedService } from '../../layouts/shared-service';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import { DialogComponent } from '../dashboard/dialog/dialog.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { HelpDialogComponent } from './help-dialog/help-dialog.component';
import { AuthNewService } from '../../service/auth-new.service';
import { StorageService } from '../../service/storage.service';


@Component({
    selector: 'app-doc-upload',
    templateUrl: './doc-upload.component.html',
    styleUrls: ['./doc-upload.component.scss']
})
export class DocUploadComponent implements OnInit {
    disabledValue
    colorLabel = 'DarkBlue';
    uploadDisabled = false;
    disablefileupload = true;
    pageTitle = 'New Analysis';
    files: FileObject[] = [];
    signedInUser: User;
    @Input() analysisDetails: AnalysisDetails;
    analyte: Analyte;
    modelChanges: Subject<string> = new Subject<string>();
    finish = false;
    step = 0;
    disableAdd: boolean=false;
    verificationStatus = true;
    verificationMessage: string;
    submitStatus = true;
    submitMessage: string;
    sub: User;
    deleteIndex: number;
    file_path: string;
    total_files: any = [];
    upload_types_SAM = ['Sample Analysis Report', 'Method Validation Report'];
    subtype: AnalysisDetails;
    submitResultLoader: boolean = false;
    disableMatPanel:boolean=false;
    @Output() matPanel = new EventEmitter<any>();

    constructor(private _sharedService: SharedService,
        private authService: AuthService,
        private router: Router,
        private dbService: DBService,
        private uploadService: UploadService,
        private newAuthService: AuthNewService,
        public dialog: MatDialog,
        private storageService: StorageService,
        private ref: ChangeDetectorRef) {
        // this._sharedService.emitChange(this.pageTitle);

        uploadService.fileUploadEvent$.subscribe(
            fileObject => this.handleFileUploadEvent(fileObject)
        );
        uploadService.disabledValue.subscribe(res => {
            this.disabledValue = res;
         
        })


    }

    private handleFileUploadEvent(fileObject: FileObject) {
        if (fileObject.status === FileObjectStatus.Deleted) {
            for (const upload of this.analyte.uploadObjs) {
                for (let i = 0; i < upload.files.length; i++) {
                    if (upload.files[i] === fileObject) {
                        upload.files.splice(i, 1);
                    }
                }
            }

        }
    }

    fileChangeEvent(fileInput: any, uploadType: string) {
        this.disableAdd = false;
       
        // tslint:disable-next-line: forin
        for (const index in this.analyte.uploadObjs) {
           
            if (this.analyte.uploadObjs[index].uploadType === uploadType) {
                if (fileInput.target.files && fileInput.target.files.length) {
                    for (let i = 0; i < fileInput.target.files.length; i++) {
                        this.analysisDetails.files=[]
                        const fileObject = new FileObject(fileInput.target.files[i], uploadType);
                       
                        this.analyte.uploadObjs[index].files.push(fileObject);
                        // this.total_files.push(fileObject.name);
                        
                        
                      
                        // this.uploadedFiles to be deleted
                    }
                //    this.analysisDetails.files = this.total_files;
                    this.analyte.tempPath = this.analyte.uploadObjs[index].path;
                    
                }

            }
        }
        fileInput.target.value = null;
    }



    getUploadObj(): RDSAnalysisData {
        const analysisKeyObj: RDSAnalysisData = {
            analysis_id: this.analysisDetails.analysisId,
            group_id: this.storageService.get('group'),
            organization_id: this.storageService.get('organization'),
            user_id: this.storageService.get('username'),
            file_name: this.analyte.uploadObjs[0].files[0].name,
            analysis_type: this.analysisDetails.analysisType,
            project_code: this.analysisDetails.projectCode,
        };
        this.analysisDetails.group = analysisKeyObj.group_id;
        this.analysisDetails.organization = analysisKeyObj.organization_id;

        return analysisKeyObj;
    }


    // initiateAnalysis() {
    //     const analysisKeyObj = this.getUploadObj();
    //     console.log(analysisKeyObj)
    //     this.dbService.verifyAnalysis(analysisKeyObj).subscribe({
    //         next: data => {
    //             console.log('DATA on Component page',data);
    //             console.log(data);
    //             if (data.processcode === 'Error') {
    //                 this.verificationStatus = false;
    //                 this.verificationMessage = 'Error, file not uploaded: file with identical name previously uploaded. Please rename the file and upload it again to continue.'
    //                 console.log(data.processcode)
    //             }
    //         },
    //         error: err => {
    //             this.verificationStatus = false;
    //             this.verificationMessage = 'Processing Error, Please contact Administrator.';
    //             console.log(err)
    //         }
    //     });
    // }


    uploadAll(uploadType: string) {
        // this.initiateAnalysis();
        this.disableMatPanel=true;
        setTimeout(() => {
          
            if (this.verificationStatus) {
                this.disablefileupload = false;
               
                this.analysisDetails.files=this.analyte.uploadObjs[0].files.map(res => res.name);
              
                this.uploadService.setAnalysisData(this.analyte);
                this.uploadService.publishUploadContainerEvent(ContainerEvents.Upload);
                this.updateAnalysisDetails(uploadType);
                this.uploadDisabled = true;
                this.disableAdd = true;
            } else {
                this.analyte.uploadObjs[0].files = [];
            }
        }, 3000);
        this.matPanel.emit(this.disableMatPanel)
    }




    uploadSingle(uploadType: string) {
        this.uploadService.setAnalysisData(this.analyte);
        this.uploadService.publishUploadContainerEvent(ContainerEvents.Upload);
        this.updateAnalysisDetails(uploadType);
    }

    getUploadPath(uploadType: string) {
      
        const relativePath = [this.signedInUser?.username,
        this.signedInUser?.userId,
        this.analysisDetails.analysisId].join('/');
       
        if (uploadType === 'Template') {
            return [relativePath, uploadType].join('/');
        } else {
            return [relativePath, this.analysisDetails.analyteName[0], uploadType].join('/');
        }
    }

    updateAnalysisDetails(uploadType: string) {

        let analyteFound = false;
        let typefound = false;
        if (this.analysisDetails.analytes.length > 0) {


            let uploadObj: Uploads;
          
            for (const obj of this.analyte.uploadObjs) {
                if (obj.uploadType === uploadType) {
                    uploadObj = obj;

                }
            }


            for (let i = 0; i < this.analysisDetails.analytes.length; i++) {
                if (this.analysisDetails.analytes[i].analyteName === this.analyte.analyteName) {
                    analyteFound = true;
                    for (let j = 0; j < this.analysisDetails.analytes[i].uploadObjs.length; j++) {
                        if (this.analysisDetails.analytes[i].uploadObjs[j].uploadType === uploadType) {
                            typefound = true;
                          
                            this.analysisDetails.analytes[i].uploadObjs[j].files = []
                            this.analysisDetails.analytes[i].uploadObjs[j].files
                                .push(...JSON.parse(JSON.stringify(uploadObj.files)));
                          

                            break;
                        }
                    }
                    if (!typefound) {
                    
                        for (let k = 0; k < this.analyte.uploadObjs.length; k++) {
                            if (this.analyte.uploadObjs[k].uploadType === uploadType) {
                                this.analysisDetails.analytes[i].uploadObjs.push(JSON.parse(JSON.stringify(this.analyte.uploadObjs[k])));
                            }
                        }
                    }
                }
            }
            if (!analyteFound) {
                this.analysisDetails.analytes.push(JSON.parse(JSON.stringify(this.analyte)));
            }
        } else {
            this.analysisDetails.analytes.push(JSON.parse(JSON.stringify(this.analyte)));
        }
    }



    clearAll() {
      
        this.uploadService.publishUploadContainerEvent(ContainerEvents.Delete);
    }

    ngOnInit() {
        // this.newAuthService.getCurrentUser();
        // this.newAuthService.getCurrentLoginUser.subscribe((user: any) => {
       
        this.authService.getCurrentUser((err, user: User) => {
         
            this.signedInUser = user;
            this.uploadService.setSignedInUser(this.signedInUser);
            if (!this.signedInUser) {
                this.router.navigate(['/extra-layout/signin']);
                return;
            } else {
                this.setRegion();
              
                // House Keeping - Initialize necessary objects
                this.analysisDetails.analytes = [];
                this.analyte = new Analyte(this.analysisDetails.analyteNames[0])

                if (this.analysisDetails.analysisType === 'SMR' || this.analysisDetails.analysisType === 'LMR' ||
                    this.analysisDetails.analysisType === 'ADA' || this.analysisDetails.analysisType === 'qPCR') {
                    this.analyte.uploadObjs = [];
                    let uploadType = 'Report'
                    if (this.analysisDetails.analysisSubtype === 'SA0') {
                        uploadType = 'Sample Analysis Report'
                    } else if (this.analysisDetails.analysisSubtype === 'MV0') {
                        uploadType = 'Method Validation Report'
                    }
                    if (this.analysisDetails.analysisSubtype === 'SAM') {
                        let i = 0;
                        for (const upload_type of this.upload_types_SAM) {
                            const obj: Uploads = {
                                uploadType: upload_type,
                                index: i,
                                path: this.getUploadPath(upload_type),
                                files: []
                            };
                            this.analyte.uploadObjs.push(obj);
                            i++;
                          
                        }
                    } else {
                        const obj: Uploads = {
                            uploadType: uploadType,
                            index: 0,
                            path: this.getUploadPath('Report'),
                            files: []
                        };
                        this.analyte.uploadObjs.push(obj);
                     
                        this.total_files=obj.files.map(res => res.name)
                        this.file_path = obj.path;
                        this.analysisDetails.file_path = this.file_path;
                     
                    }
                }

                this.subtype = this.analysisDetails;
            }
        });



    }

    setRegion() {
        const queryParams = this.router.routerState.snapshot.root.queryParams;
      
        if (queryParams && queryParams.region) {
            this.uploadService.setRegion(queryParams.region);
        }
    }

    onChangeAnalysisType(str) {
        this.modelChanges.next(str);
    }

    openDialog() {
        // console.log('type', data);
        // this.dialog.open(HelpDialogComponent, {data: {name: data}, disableClose: false, width: '82%'});
        this.router.navigate(['/default-layout/template', {
            a_type: this.subtype.analysisType, a_subtype: this.subtype.analysisSubtype
        }]);
    }


    setStep(index: number) {
        this.step = index;
        this.uploadService.publishUploadContainerEvent(ContainerEvents.Delete);
    }

    nextStep() {
        this.uploadService.publishUploadContainerEvent(ContainerEvents.Delete);
        this.step++;
    }

    prevStep() {
        this.step--;
        this.clearAll();
    }

    submit() {
        this.submitMessage='';
        this.disableAdd=true;
        this.disabledValue=true
        this.submitResultLoader = true;
        this.getUploadObj();
     
        this.uploadService.submitForAnalysis(this.analysisDetails).subscribe(
            (result) => {
              
                setTimeout(() => {
                if (this.analysisDetails.analysisType === 'SMR' || this.analysisDetails.analysisType === 'LMR' ||
                    this.analysisDetails.analysisType === 'ADA' || this.analysisDetails.analysisType === 'qPCR') {
                    
                        this.router.navigate(['/default-layout/molecule-analysis', this.analysisDetails.analysisId,
                            this.signedInUser.username]);
                   
                      
                } else {
                    this.router.navigate(['/default-layout/large-molecule', this.analysisDetails.analysisId]);
                }
            },2000)

            },
            (error) => {
           
                this.disabledValue=false
                this.submitResultLoader = false;
                this.submitStatus=false;
                this.submitMessage=error.error.message;
            }
        );
    }

    getdeleteIndex(uploadObjsindex: number, fileuploadObjsindex: number) {
        this.analyte.uploadObjs[uploadObjsindex].files.splice(fileuploadObjsindex, 1)
      
       
    }

}
