// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  float: right;
  margin: 10px;
}

.img-fluid {
  float: right;
  margin-left: 20px;
}

#date {
  float: left;
  font-style: italic;
  margin: 20px;
}

.heading {
  text-decoration: underline;
}

.file {
  margin: 20px;
  text-align: center;
}

.fileheading {
  margin-top: 30px;
  text-align: center;
}

.row {
  float: left;
  margin: 20px;
  border: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/small-molecule/pdf-layout/pdf-layout.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;AACJ;;AAEC;EACI,YAAA;EACA,iBAAA;AACL;;AAOC;EACI,WAAA;EACA,kBAAA;EACA,YAAA;AAJL;;AAOG;EACC,0BAAA;AAJJ;;AAQG;EACE,YAAA;EACA,kBAAA;AALL;;AAQG;EACE,gBAAA;EACA,kBAAA;AALL;;AAQG;EACC,WAAA;EACA,YAAA;EACA,WAAA;AALJ","sourcesContent":[".btn{\n    float: right;\n    margin: 10px;\n }\n \n .img-fluid{\n     float: right;\n     margin-left: 20px;\n }\n // table thead th { \n //     color: white;\n //      font-size:10pt;\n //       background-color: #3b5998\n //     }\n \n #date{\n     float: left;\n     font-style: italic;\n     margin:20px ;\n   }\n \n   .heading{\n    text-decoration: underline;\n   }\n \n  \n   .file{\n     margin: 20px;\n     text-align: center;\n   }\n \n   .fileheading{\n     margin-top: 30px;\n     text-align: center;\n \n   }\n   .row{\n    float: left;\n    margin: 20px;\n    border: 2px;\n   }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
