// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload button {
  margin-left: 5px;
}

.section-title {
  margin-bottom: 50px;
}

.file-input-btn {
  margin-bottom: 0;
}

.upload .previews {
  margin: 0 10px;
}

.upload .localsubmit {
  margin: 0 10px;
}

.example-headers-align .mat-expansion-panel-header-title,
.example-headers-align .mat-expansion-panel-header-description {
  flex-basis: 0;
}

.example-headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

mat-form-field {
  margin-right: 12px;
}

.btn {
  margin-right: 8px;
}

.mat-action-row {
  border-top-style: none;
  padding: 4px 4px 4px 4px;
}

.rt-full-width {
  width: 70%;
}

ol {
  columns: 1;
  -webkit-columns: 1;
  -moz-columns: 1;
}

li {
  margin-top: 5px;
  margin-left: 10px;
  padding: 2px;
}

.fileupload-drop-zone {
  border: 1px solid green;
  height: 50px;
}

.divLabel {
  float: left;
  border-radius: 10%;
  height: 32px;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/doc-upload/doc-upload.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAIE;EACE,cAAA;AADJ;;AAME;EACE,cAAA;AAHJ;;AAOA;;EAEE,aAAA;AAJF;;AAOA;EACE,8BAAA;EACA,mBAAA;AAJF;;AAOA;EACE,kBAAA;AAJF;;AAOA;EACE,iBAAA;AAJF;;AAMA;EACE,sBAAA;EACA,wBAAA;AAHF;;AAKA;EACE,UAAA;AAFF;;AAIA;EACE,UAAA;EACA,kBAAA;EACA,eAAA;AADF;;AAGA;EACE,eAAA;EACA,iBAAA;EACA,YAAA;AAAF;;AAEA;EACE,uBAAA;EACA,YAAA;AACF;;AACA;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;AAEF","sourcesContent":[".upload {\n  button {\n    margin-left: 5px;\n  }\n}\n.section-title {\n  margin-bottom: 50px\n}\n\n.file-input-btn {\n  margin-bottom: 0\n}\n\n.upload {\n  .previews {\n    margin: 0 10px\n  }\n}\n\n.upload {\n  .localsubmit {\n    margin: 0 10px\n  }\n}\n\n.example-headers-align .mat-expansion-panel-header-title,\n.example-headers-align .mat-expansion-panel-header-description {\n  flex-basis: 0;\n}\n\n.example-headers-align .mat-expansion-panel-header-description {\n  justify-content: space-between;\n  align-items: center;\n}\n\nmat-form-field {\n  margin-right: 12px;\n}\n\n.btn {\n  margin-right: 8px;\n}\n.mat-action-row {\n  border-top-style:none;\n  padding: 4px 4px 4px 4px;\n}\n.rt-full-width {\n  width: 70%;\n}\nol {\n  columns: 1;\n  -webkit-columns: 1;\n  -moz-columns: 1;\n}\nli{\n  margin-top: 5px;\n  margin-left: 10px;\n  padding: 2px;\n}\n.fileupload-drop-zone{\n  border: 1px solid green;\n  height: 50px;\n}\n.divLabel{\n  float: left;\n  border-radius: 10%;\n  height: 32px;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
