// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  max-height: 600px; /* Adjust the value as needed */
  overflow-y: auto;
  margin: 20px;
  padding: 20px;
}

.heading {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/small-molecule/column-dialog/column-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA,EAAA,+BAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;AACJ;;AAEE;EACE,0BAAA;AACJ","sourcesContent":[".modal-content {\n    max-height: 600px; /* Adjust the value as needed */\n    overflow-y: auto;\n    margin: 20px;\n    padding: 20px;\n  }\n\n  .heading{\n    text-decoration: underline;\n   }\n "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
