// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fill-remaining-space {
  /* This fills the remaining space, by using flexbox.
     Every toolbar row uses a flexbox row layout. */
  flex: 1 1 auto;
}

.container {
  padding: 10px;
  margin: 20px;
}

.form {
  min-width: 150px;
  max-width: 700px;
  width: 100%;
}

.form-element {
  padding: 5px 0px 25px 2px;
  width: 100%;
}

.button {
  margin: 75px 1px;
}

.error {
  font-size: 12px;
}

::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/new-analysis/new-analysis.component.scss"],"names":[],"mappings":"AAAA;EACE;mDAAA;EAEA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;EACA,WAAA;AACF;;AAEA;EACE,yBAAA;EACA,WAAA;AACF;;AAEA;EAEE,gBAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAIE;EACE,uBAAA;AADJ","sourcesContent":[".fill-remaining-space {\n  /* This fills the remaining space, by using flexbox.\n     Every toolbar row uses a flexbox row layout. */\n  flex: 1 1 auto;\n}\n\n.container {\n  padding: 10px;\n  margin: 20px;\n}\n\n.form {\n  min-width: 150px;\n  max-width: 700px;\n  width: 100%;\n}\n\n.form-element {\n  padding: 5px 0px 25px 2px;\n  width: 100%;\n}\n\n.button {\n\n  margin: 75px 1px;\n}\n\n.error {\n  font-size: 12px;\n}\n\n::ng-deep {\n  .mdc-text-field--filled:not(.mdc-text-field--disabled) {\n    background-color:white;\n}\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
