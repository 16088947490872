import { ASTWithName } from '@angular/compiler';
import { Injectable } from '@angular/core';
// import { throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { BehaviorSubject, EmptyError, Observable, observable, of } from 'rxjs';
import { tryCatch } from 'rxjs/internal-compatibility';
import { delay, timeInterval } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { environment } from '../../environments/environment';
import { callbackify } from 'util';
import Amplify, { Auth, Storage } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthNewService {
  user = new Subject<CognitoUser>();
  currentUser!: CognitoUser;
  passwordChanged = new BehaviorSubject<boolean>(false);
  forgotPasswordCode = new BehaviorSubject<boolean>(false);
  forgotPasswordMessage = new BehaviorSubject<string>('');
  userGroup = new BehaviorSubject('');
  groups;
  forgotMessage;
  signinError = new Subject<string>();
  getCurrentLoginUser = new Subject<CognitoUser>();
  private userPool = new CognitoUserPool(environment.userPool);
  RevokeTokenURL: string = environment.serverUrl + '/revoke_token'

  constructor(private router: Router,
    private http: HttpClient,
    private storageService: StorageService) { }


  // function to signin
  signIn(username: string, password: string) {
    Auth.signIn(username, password)
    .then((user: any) => {
     
      this.currentUser = user;
      const accessToken = user.signInUserSession?.accessToken['jwtToken']
          if (user.attributes !== undefined) {
            this.storageService.set('username', user['username']);
            this.storageService.set('organization', user['attributes']['custom:organization']);
            this.storageService.set('group', user['attributes']['custom:group']);
            this.storageService.set('access', user['attributes']['custom:access']);
            this.storageService.set('refreshToken', JSON.stringify(user['signInUserSession']['refreshToken']))
          }
          if (accessToken) {
            const tokenParts = accessToken.split('.');
            const header = JSON.parse(atob(tokenParts[0]));
            const payload = JSON.parse(atob(tokenParts[1]));
            this.storageService.set('location', payload['location'])
          } else {
            console.error('No access token found');
          }
  
      this.user.next(user);
     
    })
    .catch((error) => {

      this.signinError.next(error);

    })

  }

  confirmCode(code: string) {
    
    Auth.confirmSignIn(this.currentUser, code)
    .then((data: any) => {
      this.user.next(data);
    })
    .catch((error) => {
      console.log(error);
    })
  }
  // function to handle new password
  newPassword (password) {
    Auth.completeNewPassword(this.currentUser, password)
    .then((res) => {
     
      this.passwordChanged.next(true);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  // function to get the current user
  getCurrentUser(User?) {

    // Auth.currentSession();
    // const user=Auth.currentSession().then(res =>
    //   {
    //     return res.getAccessToken().payload.sub;
    //   }
    //   );
  //  console.log(Auth.currentSession());
  
    Auth.currentAuthenticatedUser({
      bypassCache: true
    }).then(user => {
      this.getCurrentLoginUser.next(user);
     

    })
    .catch(err => {
      return err
    });

    }

  // function to signout
  async signout() {
    this.http.post(this.RevokeTokenURL, this.storageService.get('refreshToken')).subscribe(res => res)

    await Auth.currentAuthenticatedUser({
      bypassCache: true
    })
      .then(user => {

        if (user) {
          try {
            localStorage.clear();
            sessionStorage.clear();
            Auth.signOut({ global: true });
          }
          catch (error) {
            console.log('error occured while logout', error);
          }
          this.router.navigate(['extra-layout/home']);
        }
      })
      .catch(err => console.log(err));

  }

  // function to handle forget password
  // Send confirmation code to user's email
  forgetPassword(username: string) {
    Auth.forgotPassword(username)
    .then((data) => {
    
      this.forgotPasswordCode.next(true);
    })
    .catch((err) => console.log(err))
  }

  // Collect confirmation code and new password, then
  forgetPasswordConfirm (username, code, password) {
    Auth.forgotPasswordSubmit(username, code, password)
    .then((res) => {
      if (res === 'SUCCESS' ) {
        this.forgotMessage = 'password changed'
      } else {
        this.forgotMessage = 'Error occured'
      }
    })
    .catch((error) => {
      this.forgotMessage = error.message
      console.log(error)
    })
    setTimeout(() => {
      this.forgotPasswordMessage.next(this.forgotMessage);
    }, 2000);
  }

  getAuthenticatedUser() {
    return this.userPool.getCurrentUser();
  }

}
