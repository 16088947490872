import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from '../../layouts/shared-service';
import { Router } from '@angular/router';
import 'rxjs/Rx';

import { DBService } from '../../service';
import { DownLoadService } from '../download';
import { AuthService } from '../../service';
import { User } from '../../model/types';
import { AnalysisKey, PreviousAnalysis, RDSAnalysisData } from '../../model/analysis-details.model';
import { DropDownService } from '../../service/drop-down.service';
import { AppLoaderService } from '../../service/app-loader/app-loader.service'
import Utils from '../../utility/Utils';
import { AuthNewService } from '../../service/auth-new.service';
import { Subscription } from 'rxjs/Rx';
import { StorageService } from '../../service/storage.service';

@Component({
    selector: 'app-previous-analysis',
    templateUrl: './previous-analysis.component.html',
    styleUrls: ['./previous-analysis.component.scss']
})
export class PreviousAnalysisComponent implements OnInit, OnDestroy {

    pageTitle = 'Previous Activities';
    signedInUser: any;
    previousAnalysisKeys: RDSAnalysisData[] = [];
    pageLoaded = false;
    signedInUserAccess: any;
    private currentLoginUser: Subscription;
    private DataSubscription: Subscription;
    role
    startDate;
    endDate;
    maxDate = new Date();
auditFlag:boolean = false
    constructor(private _sharedService: SharedService,
        private authService: AuthNewService,
        private router: Router,
        private dbService: DBService,
        private loader: AppLoaderService,
        private storageService: StorageService) {
        this._sharedService.emitChange(this.pageTitle);
        this.role = this.storageService.get('access');

    }

    openDialog() {
        this.loader.open();
    }



    ngOnInit(): void {

        this.authService.getCurrentUser();
        this.currentLoginUser = this.authService.getCurrentLoginUser.subscribe((user: any) => {
            this.signedInUser = user;
            if (!this.signedInUser) {
                this.router.navigate(['/extra-layout/signin']);
                return;
            }
            else {
                this.signedInUserAccess = this.signedInUser.attributes["custom:access"].toLowerCase();

                this.dbService.getAnalysis(this.signedInUserAccess, this.startDate, this.endDate,this.auditFlag);
            }
        }
        )

        this.DataSubscription = this.dbService.rdsAnalysisData.subscribe(
            (data: RDSAnalysisData[]) => {
                    this.previousAnalysisKeys = data;
                    this.pageLoaded = true;
                    if (this.pageLoaded == true) {
                        this.closeDialog()
                    }
                }           
        );

    }

    passSelectedDates() {
        this.previousAnalysisKeys = []
        const data = this.dbService.getAnalysis(this.role, this.startDate, this.endDate,this.auditFlag)
      
      }
    
      selectedDates(dateRangeStart, dateRangeEnd) {
        this.startDate = dateRangeStart.value;
        this.endDate = dateRangeEnd.value;
        this.passSelectedDates();
        
      }

    closeDialog() {
        this.loader.close();
    }

    ngOnDestroy(): void {
        if (this.DataSubscription) {
            this.DataSubscription.unsubscribe();
        }
        if (this.currentLoginUser) {
            this.currentLoginUser.unsubscribe();
        }
    }

}
